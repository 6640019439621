import * as moment from 'moment';
import { useEffect } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FormattedMessage, useIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { DeviceActionCreators } from "actions/DeviceActionCreators";
import { DeviceDataAPI } from "api/DeviceDataAPI";
import { MeasurableParameter, RefreshTimeoutMs, DateFormat, DateFormatDatePicker, AccessMode } from "constants/Constants";
import { ReportTypes } from "constants/NotificationConstants";
import "../../less/DeviceData.css";
import "../../less/DatePicker.css";
import "../../less/Icons.css";
import { IAppStore } from "store/configureStore";
import OnlineOffline from "../OnlineOffline";
import Spinner from "../Spinner";
import DeviceC3Chart from "./DeviceC3Chart";
import DeviceGrid from "./DeviceGrid/DeviceGrid";
import DeviceNoData from "./DeviceNoData";
import * as React from "react";
import { IDeviceDataRecordKey, IDeviceDataInfo } from 'constants/DeviceConstants';
import DeviceContext, {IDeviceContext} from "../DeviceContext";


interface IDeviceDataProps {
    data: IDeviceDataInfo;
    message: string;
    dateStart: Date;
    dateEnd: Date;
    fetchDataByDate: (deviceId: number, parameter: MeasurableParameter, from: Date, to: Date, refreshGrid: boolean) => void;
    changeDate: (dataStart: Date, dataEnd: Date) => void;
    deleteDeviceDataEntry: (model: IDeviceDataRecordKey) => void;
}

const DeviceData = (props: IDeviceDataProps) => {
    const deviceContext = React.useContext<IDeviceContext>(DeviceContext);
    useEffect(() => {
        enableRefreshing();
        return () => {
            if (timerId)
                clearInterval(timerId);
        };
    }, [])

    useEffect(() => {
        props.fetchDataByDate(deviceContext.deviceId, deviceContext.parameterId, props.dateStart, props.dateEnd, true);
        }, [deviceContext.deviceId, deviceContext.parameterId])
    
    const handleDateStartChange = (date: any) => {
        props.changeDate(date, props.dateEnd);
        props.fetchDataByDate(deviceContext.deviceId, deviceContext.parameterId, date, props.dateEnd, true);
    }

    const handleDateEndChange = (date: any) => {
        props.changeDate(props.dateStart, date);
        props.fetchDataByDate(deviceContext.deviceId, deviceContext.parameterId, props.dateStart, date, true);
    }

    let timerId: any = null;
    const enableRefreshing = () => {
        if (timerId) { clearInterval(timerId); }
        timerId = setInterval(() => {
            if (props.data && deviceContext.deviceId, deviceContext.parameterId) {
                props.fetchDataByDate(deviceContext.deviceId, deviceContext.parameterId, props.dateStart, props.dateEnd, false);
            }
        }, RefreshTimeoutMs);
    }

    const reportClickEvent = (e: any, type: ReportTypes) => {
        e.preventDefault();
        let dateStart = props.dateStart;
        let dateEnd = props.dateEnd;
        if(dateStart)
            dateStart = new Date(Date.UTC(dateStart.getFullYear(),dateStart.getMonth(), dateStart.getDate()));
        if(dateEnd)
            dateEnd = new Date(Date.UTC(dateEnd.getFullYear(),dateEnd.getMonth(), dateEnd.getDate()));

        window.location.href = DeviceDataAPI.getDeviceDataByDateLink(deviceContext.deviceId, deviceContext.parameterId, dateStart, dateEnd)
            .replace("/Device/", "/Report/")
            .replace("/DataByDate?", `/DataByDate/${type}?`);
    };

    const intl = useIntl();
    if (!(deviceContext.deviceId && deviceContext.parameterId))
        return null;

    if (props.message === "Loading")
        return (<Spinner />);
    else
        if (!props.data)
            return (<DeviceNoData />);

    return (
        <div className="device-data">
            <Container>
                <Row className="settings">
                    <div className="options-panel">
                        <div className="dates item">
                            <DatePicker
                                placeholderText={props.dateStart ? moment.utc(props.dateStart).local().format(DateFormat) : intl.formatMessage({ id: "device.date.from" })}
                                selectsStart
                                selected={props.dateStart}
                                startDate={props.dateStart}
                                endDate={props.dateEnd}
                                maxDate={props.dateEnd}
                                onChange={handleDateStartChange}
                                dateFormat={DateFormatDatePicker}
                            />
                            {props.dateStart ? <i className="material-icons" onClick={handleDateStartChange.bind(this, null)}>clear</i> : null}
                            <DatePicker
                                placeholderText={props.dateEnd ? moment.utc(props.dateEnd).local().format(DateFormat) : intl.formatMessage({ id: "device.date.to" })}
                                selectsEnd
                                selected={props.dateEnd}
                                endDate={props.dateEnd}
                                startDate={props.dateStart}
                                minDate={props.dateStart}
                                onChange={handleDateEndChange}
                                dateFormat={DateFormatDatePicker}
                            />
                            {props.dateEnd ? <i className="material-icons" onClick={handleDateEndChange.bind(this, null)}>clear</i> : null}
                        </div>
                        {(props.data.accessMode === AccessMode.Administrator || props.data.accessMode === AccessMode.Readonly)  ?
                            <div className="item">
                                <FormattedMessage id="device.edit.download" /> &nbsp;
                                <a className="icon-png xlsx" onClick={(e: any) => reportClickEvent(e, ReportTypes.Xlsx)} title="Excel"> &nbsp; </a>
                                <a className="icon-png pdf" onClick={(e: any) => reportClickEvent(e, ReportTypes.Pdf)} title="PDF"> &nbsp; </a>
                            </div>
                            : null}
                        <div className="item">
                            {
                                props.data
                                    ? <div className="status">
                                        <FormattedMessage id="device.edit.status" />
                                        <OnlineOffline isOnline={props.data.isOnline} />
                                    </div>
                                    : null
                            }
                        </div>
                    </div>
                </Row>
            </Container>

            {(props.data.records && props.data.records.length > 0 ?
                <Container>
                    <Row>
                        <Col xs={12} md={8} sm={6}>
                            {
                                props.data
                                    ? <DeviceC3Chart
                                        records={props.data.records}
                                        thresholdMax={props.data.thresholdMax}
                                        thresholdMin={props.data.thresholdMin}
                                        parameterId={props.data.parameterId}
                                        parameterMaxValue={props.data.parameterMaxValue}
                                        parameterMinValue={props.data.parameterMinValue}
                                        unit={props.data.parameterCustomUnit}
                                        customName={props.data.parameterCustomName}
                                    /> : null
                            }
                        </Col>
                        <Col xs={12} md={4} sm={6} style={{paddingLeft:0}}>
                            {
                                props.data
                                    ? <DeviceGrid
                                        removable={props.data.accessMode === AccessMode.Administrator}
                                        parameterId={props.data.parameterId}
                                        parameterUnit={props.data.parameterCustomUnit}
                                        records={props.data.records}
                                        thresholdMax={props.data.thresholdMax}
                                        thresholdMin={props.data.thresholdMin}
                                        parameterMaxValue={props.data.parameterMaxValue}
                                        parameterMinValue={props.data.parameterMinValue}
                                        deviceId={props.data.deviceId}
                                        dateStart={props.dateStart}
                                        dateEnd={props.dateEnd}
                                    /> : null
                            }
                        </Col>
                    </Row>
                </Container> : <DeviceNoData />)
            }
        </div>
    );
}

function mapStateToProps(state: IAppStore, ownProps: any) {
    return {
        data: state.deviceData.data,
        message: state.deviceData.message,
        dateStart: state.deviceData.dateStart,
        dateEnd: state.deviceData.dateEnd,
        deviceId: ownProps.deviceId,
        parameterId: ownProps.selectedParameterId
    };
}

export default connect(
    mapStateToProps,
    (dispatch: any) => bindActionCreators(DeviceActionCreators, dispatch)
)(DeviceData);
