import {FormattedMessage, useIntl} from "react-intl";
import * as React from "react";
import {ActivationCodeType} from "../../../constants/Constants";

const ShopLink = (
    props: {
        type: ActivationCodeType,
        showDescription?: boolean
    }) => {
    const intl = useIntl();

    let linkMap: Record<ActivationCodeType, [string, string]> = {
        [ActivationCodeType.Unknown]: ["", ""],
        [ActivationCodeType.Sms100]: ["https://oferta.ipomiar.pl/basket/add/42/1", "device.edit.sms-package.basket"],
        [ActivationCodeType.DataTransfer]: ["https://oferta.ipomiar.pl/basket/add/40/1", "device.edit.data-transfer.basket"], //Correct link not ready
        [ActivationCodeType.PremiumForever]: ["https://oferta.ipomiar.pl/basket/add/40/1", "device.edit.premium-package.basket"]
    }

    return (
        <a href={linkMap[props.type][0]} target="_blank"
           className="shop-link"
           style={{color: "inherit", textDecoration: "inherit"}}
           title={intl.formatMessage({id: linkMap[props.type][1]})}>

            {props.showDescription
                ? (<><FormattedMessage id={linkMap[props.type][1]}/> &raquo;</>)
                : (<i className="material-icons">shopping_basket</i>)}
        </a>
    );
}

export default ShopLink;
